(function () {
    'use strict';

    angular
        .module('app.athletes.teams.teamList')
        .controller('TeamListController', TeamsCtrl);

    function TeamsCtrl(dataservice, $q, $state, $rootScope, gettextCatalog, $scope, paramHelper, authenticationService, log, $timeout) {
        var vm = this;
        $rootScope.vm = this;
        // Page title
        $rootScope.title = gettextCatalog.getString('TeamList');

        // Dropdownlist's sources
        vm.sectionList = [];
        vm.ageGroupList = [];
        // Checkbox variables
        vm.inactiveTeams = false;
        vm.checkboxEnabled = false;
        // Grid's details
        vm.columns = [
            {type: 'checkbox', textAlign: 'center', width: 35},
            {
                field: 'csapatNev',
                headerText: gettextCatalog.getString('TeamName'),
                textAlign: 'center',
                headerTextAlign: 'center',
                width: 100,
                template: '#teamlistcolumnTemplate1'
            },
            {
                field: 'edzoNeve',
                headerText: gettextCatalog.getString('Coach'),
                textAlign: 'center',
                headerTextAlign: 'center',
                width: 125,
                template: '#teamlistcolumnTemplate2'
            },
            {
                field: 'aktiv',
                headerText: gettextCatalog.getString('Active'),
                textAlign: 'center',
                headerTextAlign: 'center',
                width: 75,
                type: 'boolean',
                displayAsCheckBox: true
            },
            {field: 'versenyzo', headerText: gettextCatalog.getString('Athletes'), textAlign: 'center', width: 75},
            {field: 'versenyzoFiu', headerText: gettextCatalog.getString('Man'), textAlign: 'center', width: 75},
            {field: 'versenyzoLany', headerText: gettextCatalog.getString('Woman'), textAlign: 'center', width: 75},
            {field: 'megjegyzes', headerText: gettextCatalog.getString('Comment'), textAlign: 'center', width: 200, headerTextAlign: 'center'}
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowSorting: true,
            allowTextWrap: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            },
            rowSelecting: function () {
                grid.clearSelection();
            },
            checkBoxChange: function (args) {
                $rootScope.checkBoxChangeOnlyOne(args, grid);
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };
        grid.dataBound = function () {
            if (grid.dataSource.length === 1) {
                grid.selectRow(0);
            }
        };

        // Public functions
        vm.inactiveChange = inactiveChange;
        vm.deleteTeam = deleteTeam;
        vm.goToAddTeam = goToAddTeam;
        vm.goToModifyTeam = goToModifyTeam;
        vm.goToTeamMembersList = goToTeamMembersList;
        vm.openCoach = openCoach;
        vm.sectionChange = sectionChange;
        vm.ageGroupChange = ageGroupChange;
        vm.openTeamResults = openTeamResults;

        // Activate page
        activate();

        function activate() {
            authenticationService.getRight('MEGTCSOPORT').then(function (result) {
                if (result !== true) {
                    log.permissionError(true);
                } else {
                    vm.MEGTCSOPORT = result;
                    $q.all([paramHelper.getParams([], ['teams.sectionId', 'teams.ageGroupId', 'teams.inactiveTeams']), authenticationService.getRight('TORLCSOPORT'), authenticationService.getRight('MODCSOPORT'), authenticationService.getRight('UJCSOPORT'), authenticationService.getRight('MEGTCSERJEL')]).then(function (results) {
                        vm.defaultSection = results[0]['teams.sectionId'];
                        vm.defaultAgeGroup = results[0]['teams.ageGroupId'];
                        vm.inactiveTeams = results[0]['teams.inactiveTeams'];
                        vm.TORLCSOPORT = results[1];
                        vm.MODCSOPORT = results[2];
                        vm.UJCSOPORT = results[3];
                        vm.MEGTCSERJEL = results[4];
                        if (!vm.inactiveTeams) {
                            vm.inactiveTeams = false;
                        }
                        angular.element('#active').ejCheckBox({checked: vm.inactiveTeams});
                        getSections();
                    });
                }
            });
        }

        function openTeamResults() {
            if (vm.MEGTCSERJEL) {
                if ($rootScope.checkRowSelection(grid, true)) {
                    var selectedRecords = $rootScope.getSelectedItems(grid);
                    paramHelper.setParams([{'teamID': selectedRecords[0].csapatId}]);
                    $state.go('reports.raceResultsReport');
                }
            } else {
                log.permissionError();
            }
        }

        // Get sections' list
        function getSections() {
            return dataservice.sectionDropDownList().then(function (data) {
                vm.sectionList = data.itemsList;
                if (vm.sectionList.length === 1) {
                    $timeout(function () {
                        vm.sectionId = vm.sectionList[0].value;
                    }, 0);
                } else {
                    $timeout(function () {
                        vm.sectionId = vm.defaultSection;
                    }, 0);
                }
            });
        }

        // Run after section selected
        function sectionChange(args) {
            vm.checkboxEnabled = true;
            if (!vm.ageGroupId && !vm.defaultAgeGroup) {
                getTeams(args.value, 0, vm.inactiveTeams);
            }
            getAgeGroups(args.value);
            paramHelper.setParams([{'teams.sectionId': args.value}]);
        }

        // Get age groups' list
        function getAgeGroups(sectionId) {
            return dataservice.ageGroupDropDownList(sectionId).then(function (data) {
                if (data.itemsList.length > 1) {
                    data.itemsList.unshift({value: '0', text: gettextCatalog.getString('All')});
                }
                vm.ageGroupList = data.itemsList;
                if (vm.ageGroupList.length === 1) {
                    $timeout(function () {
                        vm.ageGroupId = vm.ageGroupList[0].value;
                    }, 0);
                } else {
                    if (vm.sectionId == vm.defaultSection) {
                        $timeout(function () {
                            vm.ageGroupId = vm.defaultAgeGroup;
                        }, 0);
                    } else {
                        $timeout(function () {
                            vm.ageGroupId = 0;
                        }, 0);
                    }
                }
            });
        }

        // Run after age group selected
        function ageGroupChange(args) {
            getTeams(vm.sectionId, args.value, vm.inactiveTeams);
            paramHelper.setParams([{'teams.ageGroupId': args.value}]);
        }

        // Get teams' list
        function getTeams(sectionId, ageGroupId, active) {
            return dataservice.teamList(sectionId, ageGroupId, active).then(function (data) {
                grid.dataSource = data.itemsList;
            });
        }

        // when click inactives checkbox
        function inactiveChange(args) {
            vm.inactiveTeams = args.value;
            paramHelper.setParams([{'teams.inactiveTeams': args.value}]);
            if (vm.sectionId) {
                getTeams(vm.sectionId, vm.ageGroupId, args.value);
            }
        }

        function deleteTeam() {
            if (vm.TORLCSOPORT) {
                if ($rootScope.checkRowSelection(grid, true)) {
                    var selectedRecords = $rootScope.getSelectedItems(grid);
                    if (selectedRecords[0].versenyzo > 0) {
                        swal({
                            title: gettextCatalog.getString('AreYouSureYouWantToDeleteTheTeamWithMembers'),
                            text: gettextCatalog.getString('TeamHasMembers'),
                            type: 'warning',
                            showCancelButton: true,
                            confirmButtonText: gettextCatalog.getString('Delete'),
                            cancelButtonText: gettextCatalog.getString('Cancel')
                        }).then(function (isConfirm) {
                            if (isConfirm.value) {
                                deleteTeamById(selectedRecords[0].csapatId);
                            }
                        });
                    } else if (selectedRecords[0].aktiv === true) {
                        swal({
                            title: gettextCatalog.getString('AreYouSureYouWantToDeleteTheTeam'),
                            text: gettextCatalog.getString('MakesTeamPassive'),
                            type: 'warning',
                            showCancelButton: true,
                            confirmButtonText: gettextCatalog.getString('Delete'),
                            cancelButtonText: gettextCatalog.getString('Cancel')
                        }).then(function (isConfirm) {
                            if (isConfirm.value) {
                                deleteTeamById(selectedRecords[0].csapatId);
                            }
                        });
                    } else {
                        log.errorMsg('YouCantDeleteInactiveTeam');
                    }
                }
            } else {
                log.permissionError();
            }
        }

        function deletePlayersFromTeam(id) {
            return dataservice.deleteTeamsPlayers(id)
                .then(function (data) {
                    if (data.retValue !== true) {
                        log.errorMsg('CantDeleted');
                    } else {
                        getTeams(vm.sectionId, vm.ageGroupId, vm.inactiveTeams);
                    }
                });
        }

        function deleteTeamById(id) {
            return dataservice.deleteTeam(id).then(function (data) {
                if (data.retValue === true) {
                    deletePlayersFromTeam(id);
                } else {
                    log.errorMsg('TeamDeletedError', 'CantDeleted');
                }
            });
        }

        function goToAddTeam() {
            if (vm.UJCSOPORT) {
                paramHelper.setParams([{'teams.sectionId': vm.sectionId}, {'teams.ageGroupId': vm.ageGroupId}, {'teams.inactiveTeams': vm.inactiveTeams}]);
                $state.go('athletes.teams.addTeam');
            } else {
                log.permissionError();
            }
        }

        function goToModifyTeam() {
            if (vm.MODCSOPORT || vm.MEGTCSOPORT) {
                if ($rootScope.checkRowSelection(grid, true)) {
                    var selectedRecords = $rootScope.getSelectedItems(grid);
                    paramHelper.setParams([{'teams.sectionId': vm.sectionId}, {'teams.ageGroupId': vm.ageGroupId}, {'teams.inactiveTeams': vm.inactiveTeams}, {'teams.team': selectedRecords[0].csapatId}]);
                    $state.go('athletes.teams.modifyTeam');
                }
            } else {
                log.permissionError();
            }
        }

        function goToTeamMembersList(szakosztaly, korosztaly, csapat, inaktiv) {
            if (vm.MODCSOPORT) {
                if (szakosztaly && korosztaly && csapat && angular.isDefined(inaktiv)) {
                    paramHelper.setParams([{'teams.sectionId': szakosztaly}, {'teams.ageGroupId': korosztaly}, {'teams.inactiveTeams': vm.inactiveTeams}, {'teams.team': csapat}]);
                    $state.go('athletes.teams.teamMembersList');
                } else {
                    if ($rootScope.checkRowSelection(grid, true)) {
                        var selectedRecords = $rootScope.getSelectedItems(grid);
                        var sectionName;
                        var ageGroupName;
                        if (selectedRecords[0].aktiv !== false) {
                            vm.sectionList.forEach(elem => {
                                if (elem.value == vm.sectionId) {
                                    sectionName = elem.text;
                                    return;
                                }
                            });
                            vm.ageGroupList.forEach(elem => {
                                if (elem.value == vm.ageGroupId) {
                                    ageGroupName = elem.text;
                                    return;
                                }
                            });
                            paramHelper.setParams([{'teams.sectionId': selectedRecords[0].szakosztalyId}, {'teams.ageGroupId': selectedRecords[0].korosztalyId}, {'teams.inactiveTeams': vm.inactiveTeams}, {'teams.team': selectedRecords[0].csapatId}]);
                            $state.go('athletes.teams.teamMembersList');
                        } else {
                            log.errorMsg('YouCantViewInactiveTeamsMembers');
                        }
                    }
                }
            } else {
                log.permissionError();
            }
        }

        function openCoach(edzoId) {
            $q.all([authenticationService.getRight('MODFELH'), authenticationService.getRight('MEGTFELH')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'newUser.userId': edzoId}]);
                    $state.go('settings.userManagement.modifyUser');
                } else {
                    log.permissionError();
                }
            });
        }
    }
})();
