(function () {
    'use strict';

    angular
        .module('app.search')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'search',
                config: {
                    url: '/search',
                    templateUrl: 'app/search/search.html',
                    title: 'Search',
                    settings: {
                        navId: 170,
                        level: 1,
                        order: 7,
                        parentId: 0,
                        content: 'Search',
                        faIcon: 'fa-search',
                        permission: ['0', '1', '2', '3', '4'],
                        activatorPermission: [
                            '"EDZOKERESO"',
                            '"SPORTOLOKERESO"'
                        ]
                    }
                }
            }
        ];
    }
})();
