(function () {
    'use strict';

    angular
        .module('app.athletes')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'athletes',
                config: {
                    title: 'Athletes',
                    url: '/athletes',
                    templateUrl: 'app/athletes/athletes.html',
                    settings: {
                        navId: 1,
                        level: 1,
                        order: 1,
                        parentId: 0,
                        content: 'Athletes',
                        faIcon: 'fa-running',
                        permission: ['0', '1', '2', '3', '4'],
                        activatorPermission: [
                            '"MEGTKOROKEZ"',
                            '"MEGTSPORTLIST"',
                            '"MEGTCSOPORT"',
                            '"SZAKOSZTALY"',
                            '"MEGTSPORTLIST"'
                        ]
                    }
                }
            }
        ];
    }
})();
